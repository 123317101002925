import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import campanhaImg from "../../assets/images/png/campanha.png";
import semImagem from "../../assets/images/png/360x360.png";
import estrela from "../../assets/images/svg/estrela.svg";
import smileFeliz from "../../assets/images/svg/smileFeliz.svg";
import click from "../../assets/images/png/click.png";
import qrCode from "../../assets/images/svg/qrCode.svg";
import Mask from "../../services/mask";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import moment from "moment";
import storage from "../../services/storage";
import Menu from "../../Components/Menu";
import draftToHtml from "draftjs-to-html";
import Avatar from "../../Components/Avatar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function Home() {
  const { load, setLoad } = useLoad();
  const [pontosSaldo, setPontosSaldo] = useState(0);
  const [pontosCreditados, setPontosCreditados] = useState(0);
  const [performance, setPerformance] = useState({});
  const [pontos, setPontos] = useState({});
  const [banner, setBanner] = useState([]);
  const [campanha, setCampanha] = useState([]);
  const [noticia, setNoticia] = useState([]);
  const [eu, setEu] = useState([]);
  const [verMais, setVerMais] = useState(2);
  const [ranking, setRanking] = useState([]);
  const [infoPopUp, setInfoPopUp] = useState([]);
  const [modal, setModal] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [okCiente, setOkCiente] = useState(false);

  const navigate = useNavigate();

  async function fnBanner() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("banner/portal");
      setBanner(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCampanha() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("campanha?status=ativo");
      setCampanha(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnNoticia() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("noticia");
      setNoticia(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnUsuario() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("eu");
      setEu(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnRankingResumo() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("ranking/meu-resumo");
      setRanking(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPerformanceResumo() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("performance/meu-resumo");
      setPerformance(response.data);
      setPontosCreditados(response.data.pontosGanhos);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPontos() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("campanha/meu-resumo");
      setPontos(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  // async function fnPontosExtract() {
  //   try {
  //     setLoad(true);
  //     const response = await ApiServices.apiPostCatalogo("extract");
  //        setPontosCreditados(response.creditBalance);
  //   } catch (err) {
  //     console.error("err", err);
  //   } finally {
  //     setLoad(false);
  //   }
  // }
  async function fnPontosBalance() {
    try {
      setLoad(true);
      const response = await ApiServices.apiPostCatalogo("balance");
      setPontosSaldo(response.balance);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCienteModal(okCiente) {
    try {
      setLoad(true);
      const response = await ApiServices.apiPost("balance", okCiente);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnModalAceite(popupId) {
    console.log(popupId)
    try {
      setLoad(true);
      const response = await ApiServices.apiPost(`popup/aceite?popUpId=${popupId}`);
      const data = await response.json();

      if (response.status == 200) {
        toast.success(data.data);
        setModal(false);
        fnModal();
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnModal(okCiente) {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("popup/portal");

      const contentBlock = htmlToDraft(response?.data?.mensagem);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const initialState = EditorState.createWithContent(contentState);
      setEditorState(initialState);

      if (response.statusCode == 200 && response.data) {
        setInfoPopUp(response.data);
        setModal(true);
      } else {
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  

  useEffect(() => {
    fnUsuario();
    fnPontos();
    fnBanner();
    fnNoticia();
    fnCampanha();
    // fnPontosExtract();
    fnModal();
    fnPontosBalance();
    fnPerformanceResumo();
    fnRankingResumo();
  }, []);
  return (
    <>
      <div className="imgBackground mb-5"> </div>
      <div
        style={{ backgroundColor: "#25293F", height: 180, color: "#DEE3EB" }}
      >
        <Menu />
      </div>

      <div className="px-3" style={{ marginTop: -120 }}>
        <div className="d-flex justify-content-center ">
          <div className="cardPerfilHome  ">
            <div className="divAvatarHome pointer">
              <Avatar nome={eu.nome} rota={"/meu-cadastro"} />
            </div>

            <div className="divInfoCardHome">
              <div className="nomeCardPerfil">{eu.nome}</div>
              {eu?.dealer?.nomeFantasia && (
                <div className="filialCardPerfil">
                  Filial {eu.dealer.nomeFantasia}
                </div>
              )}
              <hr />

              <div className="d-flex ">
                {/* <div>
                  <div className="pontosGanhos">Total de pontos creditados</div>
                  <div className="pontosPerfil">
                    {pontosCreditados?.toLocaleString("pt-br", {
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div> */}
                <div className="">
                  <div className="pontosGanhos">Saldo atual</div>
                  <div className="pontosPerfil">
                    {pontosSaldo?.toLocaleString("pt-br", {
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center mt-4"
          style={{ overflow: "hidden" }}
        >
          <Swiper
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            pagination={{
              clickable: true,
            }}
            loop={true}
            spaceBetween={30}
            className="mySwiper"
          >
            {banner?.map((e, i) => (
              <SwiperSlide key={i}>
                <img className="imgHome mb-4" src={e.urlArquivo} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="d-flex justify-content-center">
          <div
            className="d-flex justify-content-start w-100"
            style={{ maxWidth: 500 }}
          >
            {campanha?.length > 0 && (
              <div className="homeTexto1">Campanhas</div>
            )}
          </div>
        </div>
        <div
          className="d-flex justify-content-center "
          style={{ overflow: "hidden" }}
        >
          <Swiper
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay, Pagination]}
            pagination={{
              clickable: true,
            }}
            spaceBetween={30}
            className="mySwiper"
          >
            {campanha?.map((e, i) => (
              <SwiperSlide key={i}>
                <div className="d-flex justify-content-center w-100  ">
                  <div style={{ width: 200, height: 200, marginBottom: 30 }}>
                    <img
                      width={105}
                      height={105}
                      src={
                        e.urlImagem.includes("blob") ? e.urlImagem : semImagem
                      }
                      alt=""
                    />
                  </div>
                  <div className=" d-flex flex-column justify-content-start  align-items-start  ms-5">
                    <div>{e.nome}</div>
                    <div>{e.tipo}</div>
                    <button
                      className=" btn-intitucional w-100 "
                      onClick={() => {
                        navigate("/campanha-detalhe/" + e.campanhaId);
                      }}
                    >
                      Como funciona
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="d-flex justify-content-center">
          <div
            className="d-flex flex-column justify-content-start w-100"
            style={{ maxWidth: 500 }}
          >
            {ranking?.length > 0 && <div className="homeTexto1">Ranking</div>}

            {ranking?.map((e, i) => (
              <div key={i}>
                <div className="cardRanking mt-3 position-relative">
                  <div
                    className="d-flex w-100"
                    style={{ position: "absolute", left: 90, fontSize: 12 }}
                  ></div>
                  <div className="d-flex align-items-center">
                    <img className="mx-2" width={50} src={estrela} alt="" />
                    <div
                      className="posicaoRanking mx-2"
                      style={{ minWidth: 0 }}
                    >
                      {e.posicao && e.posicao + "°"}
                    </div>

                    <img className="mx-2" width={40} src={smileFeliz} alt="" />
                    <div className="d-flex flex-column mx-2">
                      <div
                        className="nomeCardPerfil tresPontinhos"
                        style={{ maxWidth: 300 }}
                      >
                        {e.participanteNome}
                      </div>
                      {e.participanteGrupoNome}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="d-flex justify-content-center mt-5">
              <Link to={"/ranking-completo"}>
                <button className="btn btn-secondary w-100">
                  Ver ranking completo
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column w-100" style={{ maxWidth: 500 }}>
            {noticia?.length > 0 && (
              <div className="homeTexto2 mt-5">Últimas notícias</div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column w-100" style={{ maxWidth: 500 }}>
          {noticia?.map(
            (e, i) =>
              i < verMais && (
                <div className="px-3" key={i}>
                  <Link to={"/noticia-detalhe/" + e.noticiaId}>
                    <div className="homeTexto3">
                      {moment(e.dataInicio).format("DD/MM/YYYY")}
                    </div>
                    <div className="homeTexto4">{e.titulo}</div>
                    <div className="homeTexto5">
                      {e.descricao}
                      <br />
                    </div>
                    {i + 1 != verMais && <hr className="m-0" />}
                  </Link>
                </div>
              )
          )}
          <div className="d-flex justify-content-center ">
            {noticia.length >= verMais && (
              <button
                style={{ maxWidth: 500 }}
                className="btn btn-secondary w-50 mt-5"
                onClick={() => {
                  setVerMais(verMais + 2);
                }}
              >
                Ver mais
              </button>
            )}
          </div>
          <div className="homeTexto5 mt-5 text-center flex-column align-items-center px-5 d-md-flex d-none">
            Escaneie o QRCode abaixo e adicione um atalho nosso em seu celular.
            Assim você pode acompanhar sua evolução de qualquer lugar:
            <br />
            <br />
            <img width={200} src={qrCode} alt="" />
          </div>
        </div>
      </div>
      {modal == true && (
      // infoPopUp.map((e)=>{       })
        
  
        <div className="modalExterno">
          <div className="modalInternoPopUp">
            {infoPopUp.popUpComAceite == false && (
              <button
                className="closeButton"
                onClick={() => {
                  setModal(false);
                }}
              >
                &times;
              </button>
            )}
            <div className="tituloModalPopUp">
              {infoPopUp.titulo}
            </div>
            <div
              className="textoModalPopUp"
              dangerouslySetInnerHTML={{
                __html: draftToHtml(
                  convertToRaw(editorState.getCurrentContent())
                ),
              }}
            ></div>

            {infoPopUp.popUpComAceite == true && (
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    fnModalAceite(infoPopUp.popUpId);
                  }}
                >
                  Ok, ciente
                </button>
              </div>
            )}
          </div>
        </div>
     
      )}
    </>
  );
}
